import { keyframes } from "@chakra-ui/react";

const keyFramesList = {
  zoomInZoomOut: keyframes`
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.25, 1.25);
    }
    100% {
      transform: scale(1, 1);
    }
    `,

  pulse: keyframes`0% {
            transform: scale(0.5);
            opacity: 0
        }
    
        50% {
            transform: scale(1);
            opacity: 1
        }
    
        100% {
            transform: scale(1.3);
            opacity: 0
        }`,

  ring: keyframes`
        0% {
            transform: rotate(-15deg)
        }
    
        2% {
            transform: rotate(15deg)
        }
    
        4% {
            transform: rotate(-18deg)
        }
    
        6% {
            transform: rotate(18deg)
        }
    
        8% {
            -webkit-transform: rotate(-22deg);
            -ms-transform: rotate(-22deg);
            transform: rotate(-22deg)
        }
    
        10% {
            transform: rotate(22deg)
        }
    
        12% {
            transform: rotate(-18deg)
        }
    
        14% {
            transform: rotate(18deg)
        }
    
        16% {
            transform: rotate(-12deg)
        }
    
        18% {
            transform: rotate(12deg)
        }
    
        20% {
            transform: rotate(0deg)
        }`,

  arrow: keyframes`
        0%,
        100% {
          transform: translate(0, 0)
        }

        50% {
          transform: translate(10px, 0)
        }`,
};

export default keyFramesList;
