import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const Counter = ({ maxCount, duration, label }) => {
  const [count, setCount] = useState("0");

  const counter = (maximum, duration) => {
    let start = 0;
    const end = parseInt(maximum.substring(0, 3));
    if (end === start) return;

    let totalMilSecDur = parseInt(duration);
    let incrementTime = (totalMilSecDur / end) * 1000;

    let timer = setInterval(() => {
      start += 1;
      setCount(String(start) + maximum.substring(3));

      if (start === end) clearInterval(timer);
    }, incrementTime);
  };

  useEffect(() => {
    counter(maxCount, duration);
  }, [duration, maxCount]);

  return (
    <Box as="p">
      {Intl.NumberFormat().format(Number(count))} <span>{label}</span>
    </Box>
  );
};

export default Counter;
